import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Address,
  ID,
  Phone,
  validateCPR,
  validateCVR,
  validateEmail,
} from '@debbie/common/dist'
import { c } from '@debbie/cortex/dist'
import * as customersAPI from '@debbie/cortex/dist/api/customers'
import { selectAuthToken } from '@debbie/cortex/dist/auth/selectors'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'

import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { selectMe } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  Button,
  Input,
  AddressInput,
  Badge,
  PhoneInput,
  Loading,
} from '@debbie/iris/dist/components'
import { Row, Col } from '@debbie/iris/dist/components/Grid'
import AlertOctagon from '@debbie/iris/dist/components/Icons/AlertOctagon'
import THEME from '@debbie/iris/dist/theme'
import Edit from '@debbie/iris/dist/components/Icons/Edit'

interface Props {
  caseId: ID
  required?: boolean
  skippable?: boolean
  onSkip?: () => void
  requireCentralRegisterId?: boolean
}

interface ProfileData {
  status: 'not-loaded' | 'loading' | 'loaded'
  name: string | null
  email: string | null
  phone: Phone | null
  address: Address | null
  cpr: string | null
  cvr: string | null
  isCompany: boolean
}

interface InitialProfileData {
  name: string
  email: string
  phone: Phone
  address: Address
  cpr: string
  cvr: string
  isCompany: boolean
}

const initialProfile = {
  name: null,
  email: null,
  phone: null,
  address: {
    address: null,
    zipcode: null,
    city: null,
    country: null,
  },
  cpr: null,
  cvr: null,
  isCompany: false,
}

const DebtorInfo = (props: Props) => {
  const singleCase = useSelector((s: RootState) =>
    selectCaseById(s, props.caseId),
  )
  const lang = useSelector((s: RootState) => s.lang)
  const me = useSelector(selectMe)
  const authToken = useSelector(selectAuthToken)

  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )

  const [isEditingProfile, setIsEditingProfile] = useState<boolean>(
    props.required || false,
  )
  const [profileData, setProfileData] = useState<ProfileData>({
    ...initialProfile,
    status: 'not-loaded',
  })
  const [initialProfileData, setInitialProfileData] =
    useState<InitialProfileData>(initialProfile)

  const [emailValidations, setEmailValidations] = useState<string[] | null>(
    null,
  )
  const [cprValidations, setCprValidations] = useState<string[] | null>(null)
  const [cvrValidations, setCvrValidations] = useState<string[] | null>(null)

  useEffect(() => {
    if (singleCase && customer && profileData.status === 'not-loaded') {
      getProfileData()
    }
  }, [singleCase, customer])

  useEffect(() => {
    if (singleCase && !customer) {
      c.customers.getById(singleCase.customerId, true)
    }
  }, [singleCase])

  const getProfileData = () => {
    const debtorUser =
      me && customer && customer.users.find((user) => user.userId === me.id)

    if (debtorUser) {
      setProfileData({
        status: 'loaded',
        email: debtorUser.details.email,
        name: debtorUser.details.name,
        phone: debtorUser.details.phone,
        address: debtorUser.details.address,
        cpr: debtorUser.details.cpr,
        cvr: debtorUser.details.cvr,
        isCompany: debtorUser.details.isCompany,
      })
      setInitialProfileData({
        email: debtorUser.details.email,
        name: debtorUser.details.name,
        phone: debtorUser.details.phone,
        address: debtorUser.details.address,
        cpr: debtorUser.details.cpr,
        cvr: debtorUser.details.cvr,
        isCompany: debtorUser.details.isCompany,
      })
    } else if (profileData.status !== 'loading') {
      setProfileData({
        ...profileData,
        status: 'loading',
      })
    }
  }

  const requestDebtorInfoChange = async () => {
    try {
      if (!me || authToken.length === 0 || !singleCase) {
        return
      }

      const emailValidations = checkEmail(profileData.email)
      if (emailValidations) {
        console.log('emailValidations', emailValidations)
        setEmailValidations(emailValidations)
        return
      }

      const cprValidations = checkCpr(profileData.cpr)
      if (!profileData.isCompany && !initialProfileData.cpr && cprValidations) {
        console.log('cprValidations', cprValidations)
        setCprValidations(cprValidations)
        return
      }

      const cvrValidations = checkCvr(profileData.cvr)
      if (profileData.isCompany && cvrValidations) {
        console.log('cvrValidations', cvrValidations)
        setCvrValidations(cvrValidations)
        return
      }

      const { isCompany, status, ...newData } = profileData

      const update = {}

      Object.keys(newData).find((key) => {
        if (initialProfileData[key] !== newData[key]) {
          update[key] = newData[key]
        }
      })

      if (Object.keys(update).length > 0) {
        await customersAPI.requestInformationChange(
          customer.customerId,
          me.id,
          singleCase.caseId,
          update,
        )

        setInitialProfileData({ isCompany, ...newData })

        c.cases.getById(props.caseId)
        c.customers.getById(customer.customerId)

        setIsEditingProfile(!isEditingProfile)
      } else if (props.skippable) {
        if (props.onSkip) {
          props.onSkip()
        }
      } else {
        return alert(lang.debtorWeb.userInfoUpdateNoDataChanged)
      }
      if (props.onSkip) {
        props.onSkip()
      }
    } catch (error) {
      console.error(error)
      alert('Error')
    }
  }

  const checkEmail = (email: string | null) => {
    return !email || !validateEmail(email)
      ? [lang.customers.errorEmailValidation]
      : null
  }

  const checkCpr = (cpr: string | null) => {
    const validatedCpr = validateCPR(cpr, true)
    if (props.requireCentralRegisterId) {
      if (!cpr) {
        return [lang.customers.errorCPRValidation]
      } else if (!validatedCpr) {
        return [lang.customers.errorCPRValidation]
      }
    } else if (cpr) {
      if (!validatedCpr) {
        return [lang.customers.errorCPRValidation]
      }
    }
    return null
  }

  const checkCvr = (cvr: string | null) => {
    const validatedCvr = validateCVR(cvr)
    if (props.requireCentralRegisterId) {
      if (!cvr || !validatedCvr) {
        return [lang.customers.errorCVRValidation]
      }
    } else if (cvr) {
      if (!validatedCvr) {
        return [lang.customers.errorCVRValidation]
      }
    }
    return null
  }

  return (
    <div style={{ padding: '0px 10px' }}>
      {profileData.status !== 'loaded' ? (
        <Loading />
      ) : (
        <Row>
          {!props.required && (
            <>
              <Edit
                onClick={() => setIsEditingProfile(!isEditingProfile)}
                style={{
                  cursor: 'pointer',
                  float: 'right',
                  height: '15px',
                }}
              />
              <br />
            </>
          )}
          {isEditingProfile ? (
            <div>
              <Input
                placeholder={lang.debtorWebHome.name}
                value={profileData.name ? profileData.name : ''}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    name: e.target.value,
                  })
                }
              />
              <Input
                required
                placeholder={lang.debtorWebHome.email}
                value={profileData.email ? profileData.email : ''}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    email: e.target.value,
                  })
                  setEmailValidations(null)
                }}
                validationErrors={emailValidations}
              />
              <PhoneInput
                placeholder={lang.debtorWebHome.phone}
                phone={profileData.phone}
                noSaveOnValid
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    phone: e,
                  })
                }
              />
              <AddressInput
                address={profileData.address ? profileData.address : undefined}
                placeholder={lang.debtorWebHome.address}
                onChange={(address) =>
                  setProfileData({
                    ...profileData,
                    address: address,
                  })
                }
              />
              {profileData.isCompany ? (
                <Input
                  placeholder={lang.debtorWebHome.cvr}
                  value={profileData.cvr ? profileData.cvr : ''}
                  type="number"
                  required={props.requireCentralRegisterId}
                  validationErrors={cvrValidations}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      cvr: e.target.value,
                    })
                    setCvrValidations(null)
                  }}
                />
              ) : !initialProfileData.cpr ? (
                <Input
                  placeholder={lang.debtorWebHome.cpr}
                  value={profileData.cpr ? profileData.cpr : ''}
                  type="number"
                  validationErrors={cprValidations}
                  required={props.requireCentralRegisterId}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      cpr: e.target.value,
                    })
                    setCprValidations(null)
                  }}
                />
              ) : null}
              <Button onClick={requestDebtorInfoChange} size="small">
                {lang.debtorWebHome.confirm}
              </Button>
            </div>
          ) : (
            <div>
              <p>
                <b>{profileData.name}</b>
              </p>
              <Row>
                <Col size="md" width="6">
                  <p>
                    {lang.debtorWebHome.phone}:{' '}
                    {profileData.phone ? (
                      `+${profileData.phone.locale} ${profileData.phone.number}`
                    ) : (
                      <Badge
                        style={{ cursor: 'pointer' }}
                        theme={THEME.WARNING}
                        onClick={() => setIsEditingProfile(!isEditingProfile)}
                      >
                        <AlertOctagon
                          style={{
                            margin: '0.2em 0 -0.2em',
                            fontSize: '1.3em',
                          }}
                        />{' '}
                        {lang.debtorWebHome.informPhone}
                      </Badge>
                    )}
                    <br />
                    {lang.debtorWebHome.email}:{' '}
                    {profileData.email || (
                      <Badge
                        style={{ cursor: 'pointer' }}
                        theme={THEME.WARNING}
                        onClick={() => setIsEditingProfile(!isEditingProfile)}
                      >
                        <AlertOctagon
                          style={{
                            margin: '0.2em 0 -0.2em',
                            fontSize: '1.3em',
                          }}
                        />{' '}
                        {lang.debtorWebHome.informEmail}
                      </Badge>
                    )}
                  </p>
                </Col>
                <Col size="md" width="6">
                  {profileData.address && profileData.address.address ? (
                    <p>
                      {profileData.address.address},<br />
                      {profileData.address.zipcode} {profileData.address.city}
                    </p>
                  ) : (
                    <p>
                      {lang.debtorWebHome.address}:{' '}
                      <Badge
                        style={{ cursor: 'pointer' }}
                        theme={THEME.WARNING}
                        onClick={() => setIsEditingProfile(!isEditingProfile)}
                      >
                        <AlertOctagon
                          style={{
                            margin: '0.2em 0 -0.2em',
                            fontSize: '1.3em',
                          }}
                        />{' '}
                        {lang.debtorWebHome.informAddress}
                      </Badge>
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Row>
      )}
    </div>
  )
}

export default DebtorInfo
