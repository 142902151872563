import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// @ts-ignore
import styled from 'styled-components'

import { CaseStatus, getCollectorOnCase } from '@debbie/common/dist/cases'
import {
  PaymentPlanStatus,
  Plan,
  getCurrentPaymentPlan,
} from '@debbie/common/dist/economy/payment-plans'
import { PaymentMethodStatus } from '@debbie/common/dist/payment-methods'
import { c, h } from '@debbie/cortex/dist'
import * as paymentPlansAPI from '@debbie/cortex/dist/api/payment-plans'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { selectMe, selectRole } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  Content,
  Panel,
  PlaceholderLoader,
  Progress,
} from '@debbie/iris/dist/components'
import { Col, Row, Container } from '@debbie/iris/dist/components/Grid'
import ArrowLeft from '@debbie/iris/dist/components/Icons/ArrowLeft'

import { Step, Info } from '../../components'
import { Steps } from '../../components/Step'
import { APIBASE } from '../../config'
import { useCaseVouchersByCase } from '@debbie/cortex/dist/case-vouchers'
import {
  paymentPlansByCaseIdKey,
  usePaymentPlansByCaseId,
} from '@debbie/cortex/dist/paymentPlans'
import q from '@debbie/cortex/dist/query'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'

const CreatePaymentPlan = () => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const lang = useSelector((s: RootState) => s.lang)
  const me = useSelector(selectMe)
  const meRole = useSelector(selectRole)
  const isCaseLoading = useSelector((s: RootState) => h.isLoading(s.cases[id]))
  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const paymentPlansLoadable = usePaymentPlansByCaseId(singleCase?.caseId)
  const paymentPlans = h.hasData(paymentPlansLoadable)
    ? paymentPlansLoadable.data
    : null
  const caseVouchers_ = useCaseVouchersByCase(singleCase?.caseId)
  const caseVouchers = h.hasData(caseVouchers_) ? caseVouchers_.data : null

  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  useEffect(() => {
    if (singleCase) c.creditors.get(singleCase.creditorId)
  }, [singleCase])

  useEffect(() => {
    if (singleCase && creditor)
      c.collectors.getById(
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      )
  }, [creditor, singleCase])

  useEffect(() => {
    if (singleCase && !paymentPlans) {
      q.invalidate([paymentPlansByCaseIdKey(singleCase.caseId)])
    }
  }, [singleCase])

  const [skipGatherInfo, setSkipGatherInfo] = useState(false)

  const [hasNescessaryInfo, setHasNescessaryInfo] = useState(false)

  const paymentPlan: Plan | null = getCurrentPaymentPlan(paymentPlans)

  const debtorUser =
    me && customer && customer.users.find((user) => user.userId === me.id)

  let STEP: Steps = Steps.SELECTPLAN

  if (!paymentPlan) {
    STEP = Steps.SELECTPLAN
  } else if (
    paymentPlan.status === PaymentPlanStatus.CREATED &&
    !skipGatherInfo &&
    !hasNescessaryInfo
  ) {
    STEP = Steps.GATHERINFO
  } else if (
    paymentPlan.plan.length !== 0 &&
    !paymentPlan.documentSignedAt &&
    paymentPlan.status === PaymentPlanStatus.CREATED
  ) {
    STEP = Steps.SIGNING
  } else if (
    paymentPlan.paymentMethodStatus !== PaymentMethodStatus.SAVED &&
    paymentPlan.plan.length !== 0
  ) {
    STEP = Steps.CONNECTCARD
  } else {
    STEP = Steps.CONGRATULATIONS
  }

  useEffect(() => {
    loadCase(id)
  }, [id])

  useEffect(() => {
    if (
      debtorUser &&
      debtorUser.details.email &&
      debtorUser.details.phone &&
      debtorUser.details.address &&
      (debtorUser.details.isCompany
        ? debtorUser.details.cvr
        : debtorUser.details.cpr)
    ) {
      setHasNescessaryInfo(true)
    }
  }, [debtorUser])

  const loadCase = async (id) => {
    if (!isCaseLoading) c.cases.getById(id)
  }
  useEffect(() => {
    if (singleCase && !customer) {
      c.customers.getById(singleCase.customerId, true)
    }
  }, [singleCase])

  return (
    <div>
      <Content fullHeight>
        <PageBase>
          <Container>
            {singleCase?.status === CaseStatus.ENDED ? (
              <h3>Sagen er afsluttet</h3>
            ) : (
              <div>
                {singleCase && (
                  // @ts-ignore
                  <Link
                    to={`/cases/${singleCase.id}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <p
                      style={{
                        color: 'grey',
                        fontSize: '.8em',
                      }}
                    >
                      <b>
                        <ArrowLeft style={{ margin: '-.2em 0px' }} />{' '}
                        {lang.debtorWebPaymentPlans.backToCase}
                      </b>
                    </p>
                  </Link>
                )}
                <Row>
                  <StyledCol size="md" width="3">
                    <Panel
                      header={
                        singleCase
                          ? `${lang.debtorWebPaymentPlans.paymentPlanOn} #${singleCase.id}`
                          : ''
                      }
                    >
                      <div>
                        <br />
                        <div>
                          <Progress
                            items={[]
                              .concat(
                                !paymentPlan || paymentPlan?.createdByMe
                                  ? [
                                      {
                                        title:
                                          lang.debtorWebPaymentPlans.selectPlan,
                                        active: STEP === Steps.SELECTPLAN,
                                        onClick:
                                          paymentPlan &&
                                          paymentPlan.status !==
                                            PaymentPlanStatus.ACTIVE &&
                                          paymentPlan.createdByMe
                                            ? async () => {
                                                try {
                                                  await paymentPlansAPI.del(
                                                    paymentPlan.paymentPlanId,
                                                  )
                                                  q.invalidate([
                                                    paymentPlansByCaseIdKey(
                                                      singleCase.caseId,
                                                    ),
                                                  ])
                                                } catch (error) {
                                                  console.error(error)
                                                  alert(
                                                    'An error occured. Please try again.',
                                                  )
                                                }
                                              }
                                            : undefined,
                                      },
                                    ]
                                  : [],
                              )
                              .concat(
                                hasNescessaryInfo
                                  ? []
                                  : [
                                      {
                                        title:
                                          lang.debtorWebPaymentPlans.gatherInfo,
                                        active: STEP === Steps.GATHERINFO,
                                      },
                                    ],
                              )
                              .concat([
                                {
                                  title: lang.debtorWebPaymentPlans.sign,
                                  active: STEP === Steps.SIGNING,
                                },
                                {
                                  title:
                                    lang.debtorWebPaymentPlans.paymentMethod,
                                  active: STEP === Steps.CONNECTCARD,
                                },
                                {
                                  title: lang.debtorWebPaymentPlans.approval,
                                  active: STEP === Steps.CONGRATULATIONS,
                                },
                              ])}
                          />
                        </div>
                        <br />
                        <Info
                          caseVouchers={caseVouchers}
                          singleCase={singleCase}
                          me={me}
                        />
                      </div>
                      {!(singleCase && paymentPlans) && <PlaceholderLoader />}
                    </Panel>
                    {collector && (
                      <p style={{ textAlign: 'center' }}>
                        {collector && collector.logo ? (
                          <img
                            height="35px"
                            alt={collector.logo.fileName}
                            src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                          />
                        ) : (
                          <span>{collector.name}</span>
                        )}
                      </p>
                    )}
                  </StyledCol>
                  <Col size="md" width="9">
                    {singleCase && caseVouchers && paymentPlans && (
                      <Step
                        singleCase={singleCase}
                        paymentPlan={paymentPlan}
                        caseVouchers={caseVouchers}
                        meRole={meRole}
                        step={STEP}
                        onSkipGatherInfo={() => setSkipGatherInfo(true)}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </PageBase>
      </Content>
    </div>
  )
}

export default CreatePaymentPlan

const StyledCol = styled(Col)`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`
